<!-- guests -->
<div class="card border-0 guest-picker">
    <div class="card-body">
        <h5 class="card-title">* Select Your Guests</h5>
        <div class="card-text">

            <div class="outer-inline-calendar ">
                <div class="row">
                    <div class="col-12">
                        <div class="row minplus-row">
                            <div class="col-6">
                                <span class="badge badge bg-primary">{{
                                    guests.adults
                                    }}</span>
                                Adults
                            </div>
                            <div class="col-6">
                                <div class="btn btn-items btn-items-decrease" style="margin-right: 8px"
                                    (click)="incrementGuests('adults', -1)">
                                    -
                                </div>
                                <div class="btn btn-items btn-items-increase" (click)="incrementGuests('adults', 1)">
                                    +
                                </div>
                            </div>
                        </div>
                        <div class="tol-divider"></div>
                        <div class="row minplus-row">
                            <div class="col-6">
                                <span class="badge badge bg-primary">{{
                                    guests.children
                                    }}</span>
                                Children
                            </div>
                            <div class="col-6">
                                <div class="btn btn-items btn-items-decrease" style="margin-right: 8px"
                                    (click)="incrementGuests('children', -1)">
                                    -
                                </div>
                                <div class="btn btn-items btn-items-increase" (click)="incrementGuests('children', 1)">
                                    +
                                </div>
                            </div>
                        </div>
                        <div class="tol-divider"></div>
                        <div class="row minplus-row">
                            <div class="col-6">
                                <span class="badge bg-primary">{{
                                    guests.infants
                                    }}</span>
                                Infants
                            </div>
                            <div class="col-6">
                                <div class="btn btn-items btn-items-decrease" style="margin-right: 8px"
                                    (click)="incrementGuests('infants', -1)">
                                    -
                                </div>
                                <div class="btn btn-items btn-items-increase" (click)="incrementGuests('infants', 1)">
                                    +
                                </div>
                            </div>
                        </div>
                        <div class="tol-divider"></div>

                    </div>
                </div>
            </div>

        </div>
        <button class="btn btn-primary mt-4" (click)="close()">Update</button>
    </div>
</div>



<!-- EO GUESTS-->
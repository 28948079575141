import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { mxvAnimations } from 'src/@tolis/animations';
import { IsoCountry } from 'src/app/models/iso-country';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DIALOG_DATA } from '../tolis-dialog/tolis-dialog-tokens';
import { TolisDialogRef } from '../tolis-dialog/tolis-dialogref';

import { SignupService } from './signup.service';
import { ToastService } from 'src/app/services/toast.service';
import { TolisDialogService } from '../tolis-dialog/tolis-dialog.service';
import { LoginComponent } from '../login/login.component';

@Component({
    selector: 'signup-dialog',
    templateUrl: './signup.dialog.html',
    styleUrls: ['./signup.dialog.css'],
    encapsulation: ViewEncapsulation.None,
    // changeDetection: ChangeDetectionStrategy.OnPush,
    animations: mxvAnimations,
    standalone: false
})
export class SignupDialog implements OnInit {
  @ViewChild('countryField') private _countryField: ElementRef;

  _unsubscribeAll: Subject<void> = new Subject();

  showRegisterForm: boolean = true;
  showVerifyCodeForm: boolean = false;
  showFinished: boolean = false;

  signUpForm: UntypedFormGroup;
  verifyForm: UntypedFormGroup;
  isoCountries: IsoCountry[];
  formError: any = {};
  // to show spinning
  working = false;
  showAlert = false;
  alert = {
    type: 'danger',
    message: '',
  };
  verifyCodeAlert = {
    type: '',
    message: '',
  };

  get c() {
    return this.signUpForm.controls;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _signupService: SignupService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _authService: AuthenticationService,
    private _dialogRef: TolisDialogRef,
    private _dialogService: TolisDialogService,
    @Inject(DIALOG_DATA) public data: any,
    private elementRef: ElementRef,
    private _toasterService: ToastService
  ) {}

  ngOnInit(): void {
    // Get the country telephone codes
    this._signupService.getCountries().subscribe();
    this._signupService.countries$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((codes: IsoCountry[]) => {
        this.isoCountries = codes;
        // Mark for check...
        // this._changeDetectorRef.markForCheck();
      });

    this.verifyForm = this.formBuilder.group({
      code: ['', [Validators.required, Validators.minLength(6)]],
      v: [''],
    });

    this.signUpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      repeatpassword: ['', [Validators.required, Validators.minLength(8)]],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      country: ['', [Validators.required]],
    });
  }

  dismiss(how: string) {
    this._dialogRef.close(how);
    //this.close();
  }
  ngAfterViewChecked(): void {
    const _dialogRef = this._dialogRef;
    if (this.elementRef.nativeElement.querySelector('#openLoginDialog')) {
      this.elementRef.nativeElement
        .querySelector('#openLoginDialog')
        .addEventListener('click', this.dismiss.bind(this, 'openLoginDialog'));
    }
  }

  countryOnChange(): void {
    this.signUpForm.get('phone').setValue(this.signUpForm.get('country').value);
    this._countryField.nativeElement.focus();
  }

  submitVerificationCode(formValues, event): void {
    this._authService.verifyCode(this.verifyForm.value).subscribe({
      next: (response) => {
        // Re-enable the form
        this.verifyForm.enable();

        this.showVerifyCodeForm = false;
        this.showFinished = true;
        this._toasterService.showSuccessToast(
          'Success',
          'You succesfully Signed-Up. You can now proceed to login using your information'
        );
      },
      error: (err) => {
        this.verifyCodeAlert = {
          type: 'danger',
          message: err.error.message,
        };
        this._changeDetectorRef.markForCheck();
      },
    });
  }

  openLogin(): void {
    this.dismiss('success');
    const tolisDialogRef = this._dialogService.open(LoginComponent, {
      data: {
        selectedProducts: [],
        multiple: true,
      },
    });
  }

  onSubmit(formValues, event): void {
    // Do nothing if the form is invalid
    if (this.invalidFieldsExist()) {
      return;
    }

    // Disable the form
    //this.signUpForm.disable();

    // Hide the alert
    this.showAlert = true;
    // Sign up

    this._authService.signUp(this.signUpForm.value).subscribe({
      next: (response) => {
        // Re-enable the form
        this.signUpForm.enable();
        // this.signUpForm.reset();

        this.alert = {
          type: 'success',
          message: '',
        };

        this.showVerifyCodeForm = true;
        this.showRegisterForm = false;
        this.verifyForm.get('v').setValue(response.v);
        // Show the alert
      },
      error: (err) => {
        let message = '';
        Object.keys(err.error).forEach((_message, index) => {
          message += `<p>${err.error[_message]}</p>`;
        });

        this.alert = {
          type: 'danger',
          message: message,
        };
        this.showAlert = true;
        this._changeDetectorRef.markForCheck();
      },
    });
    // this._registrationService.register();
  }

  invalidFieldsExist(): boolean {
    return this.signUpForm.invalid;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GautocompleteComponent } from 'src/app/modules/gautocomplete/gautocomplete.component';
import { InlineCalendarComponent } from './inline-calendar/inline-calendar.component';
import { MapComponent } from './map/map.component';
import { MxvDatepickerComponent } from './mxv-datepicker/mxv-datepicker.component';
import { PlusMinusComponent } from './plus-minus/plus-minus.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { CenterSpinnerComponent } from './center-spinner/center-spinner.component';
import { MxvRangepickerComponent } from './mxv-rangepicker/mxv-rangepicker.component';
import { SimpleLeafletMapComponent } from './simple-leaflet-map/simple-leaflet-map.component';
import { AddFavoritesComponent } from './add-favorites/add-favorites.component';
import { RouterModule } from '@angular/router';
import { MapJcComponent } from './mapjc/map.component';
import { GuestPickerComponent } from './guest-picker/guest-picker.component';
import { MxvGuestPickerComponent } from './mxv-guest-picker/mxv-guest-picker.component';

@NgModule({
  declarations: [
    GautocompleteComponent,
    MapComponent,
    MapJcComponent,
    StarRatingComponent,
    InlineCalendarComponent,
    GuestPickerComponent,
    PlusMinusComponent,
    MxvDatepickerComponent,
    CenterSpinnerComponent,
    MxvRangepickerComponent,
    MxvGuestPickerComponent,
    SimpleLeafletMapComponent,
    AddFavoritesComponent,
  ],
  imports: [CommonModule, FormsModule, RouterModule],
  exports: [
    GautocompleteComponent,
    MapComponent,
    MapJcComponent,
    StarRatingComponent,
    InlineCalendarComponent,
    GuestPickerComponent,
    PlusMinusComponent,
    MxvDatepickerComponent,
    CenterSpinnerComponent,
    SimpleLeafletMapComponent,
    AddFavoritesComponent,
  ],
})
export class ComponentsModule {}

import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { WINDOW } from './services/window.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['app.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AppComponent implements OnInit {
  title = 'Myexclusive Villas ';
  version = '0.9';

  public loggedIn: boolean;
  public user: any;

  constructor(@Inject(WINDOW) public window: Window) {
  }

  ngOnInit(): void { }
}

import { CommonModule, DOCUMENT, ViewportScroller } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_ID, Inject, NgModule } from '@angular/core';
import {
  BrowserModule,
  // provideClientHydration,
  // withI18nSupport,
} from '@angular/platform-browser';
import {
  GuardsCheckEnd,
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
  Routes,
  Scroll,
} from '@angular/router';
import { StorageModule } from '@ngx-pwa/local-storage';
import { filter } from 'rxjs/operators';
import { TolisModule } from 'src/@tolis';
import { MxvConfigModule } from 'src/@tolis/services/config/config.module';
import { AppComponent } from './app.component';
import { appConfig } from './app.config';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { LayoutComponent } from './layout/layout.component';
import { LayoutModule } from './layout/layout.module';
import { AllModule } from './modules/all.module';
import { HomeComponent } from './modules/home/home.component';
// import { RentalPhotosStandaloneComponent } from './modules/rental-photos-standalone/rental-photos-standalone.component';
import { WINDOW_PROVIDERS } from './services/window.service';
import { EnvServiceProvider } from './shared/services/env/env.service.provider';
import { NgProgressbar } from 'ngx-progressbar';
import {
  NgProgressRouter,
  provideNgProgressRouter,
} from 'ngx-progressbar/router';
import { HttpErrorInterceptor } from './helpers/http-interceptor';

const routes: Routes = [
  {
    path: 'partial-map',
    loadChildren: () =>
      import('./modules/search-raw/search.module').then((m) => m.SearchModule),
  },

  // {
  //   // path: 'gallery/:rentalId/:slug/photos/:startpos',
  //   path: 'accomodation/:rentalId/:slug/photos/:startpos',
  //   component: RentalPhotosStandaloneComponent,
  // },

  {
    path: 's',
    loadChildren: () =>
      import('./modules/shorturl/shorturl.module').then(
        (m) => m.ShorturlModule
      ),
  },

  // With Layout Children
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', component: HomeComponent },

      {
        path: 'accomodation',
        loadChildren: () =>
          import('./modules/rental/rental.module').then((m) => m.RentalModule),
      },
      {
        path: 'rental',
        loadChildren: () =>
          import('./modules/rental/rental.module').then((m) => m.RentalModule),
      },
      {
        path: 'contactus',
        loadChildren: () => import('./modules/contactus/contactus.routes'),
      },

      {
        path: 'blog',
        loadChildren: () =>
          import('./modules/blog/blog.module').then((m) => m.BlogModule),
      },
      {
        path: 'places',
        loadChildren: () =>
          import('./modules/places/places.module').then((m) => m.PlacesModule),
      },
      {
        path: 'areas',
        loadChildren: () =>
          import('./modules/areas/areas.module').then((m) => m.AreasModule),
      },
      {
        path: 'booking',
        loadChildren: () =>
          import('./modules/booking/booking.module').then(
            (m) => m.BookingModule
          ),
      },
      {
        path: 'page',
        loadChildren: () =>
          import('./modules/general-pages/general-pages.module').then(
            (m) => m.GeneralPagesModule
          ),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./modules/search/search.module').then((m) => m.SearchModule),
      },

      {
        path: 's',
        loadChildren: () =>
          import('./modules/shorturl/shorturl.module').then(
            (m) => m.ShorturlModule
          ),
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./modules/user/user.module').then((m) => m.UserModule),
      },
    ],
  },

  //No Layout Children
  {
    path: '',
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./modules/login/login.module').then((m) => m.LoginModule),
      },
    ],
  },
  { path: '**', redirectTo: '/partial-map', pathMatch: 'full' },
];
@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledNonBlocking',
    }),
    TolisModule,
    NgProgressbar,
    NgProgressRouter,
    RouterOutlet,
    MxvConfigModule.forRoot(appConfig),

    LayoutModule,
    // FooterModule,
    //BrowserModule.withServerTransition({ appId: 'myExclusiveVillas' }),

    AllModule,

    // DialogsModule, // My Dialogs

    // NotFoundModule,
    // UserModule,
    StorageModule.forRoot({ IDBNoWrap: true }),

    // NbEvaIconsModule,
  ],
  exports: [RouterModule],
  providers: [
    { provide: APP_ID, useValue: 'myExclusiveVillas' },
    WINDOW_PROVIDERS,
    EnvServiceProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    provideNgProgressRouter({
      startEvents: [GuardsCheckEnd],
      completeEvents: [NavigationEnd],
      minDuration: 300,
    }),
    // provideClientHydration(withI18nSupport()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  prevUrl = '';
  pos = [0, 0];

  constructor(
    router: Router,
    viewportScroller: ViewportScroller,
    @Inject(DOCUMENT) private document: Document
  ) {
    router.events
      .pipe(filter((e) => e instanceof Scroll))
      .subscribe((e: any) => {
        this.pos = viewportScroller.getScrollPosition();

        if (e.anchor) {
          // anchor navigation
          /* setTimeout is the core line to solve the solution */
          viewportScroller.scrollToAnchor(e.anchor);

          setTimeout(() => {
            viewportScroller.scrollToAnchor(e.anchor);
          });
        } else if (e.position) {
          // backward navigation
          window.scrollTo(e.position);
        } else {
          if (this.prevUrl.split('?')[0] !== e.routerEvent.url.split('?')[0]) {
            // viewportScroller.scrollToPosition([0, 0]);
            document.documentElement.style.scrollBehavior = 'auto';
            setTimeout(() => window.scrollTo(0, 0), 5);
            setTimeout(
              () => (document.documentElement.style.scrollBehavior = 'smooth'),
              4
            );
          } else {
          }
        }
        this.prevUrl = e.routerEvent.url;
      });
  }
}
/**
 * O theos
 */

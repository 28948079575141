import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  private _shortUrl: BehaviorSubject<string | null> = new BehaviorSubject(null);

  /**
   * Constructor
   *
   * @param httpClient
   */
  constructor(private httpClient: HttpClient) {}

  // ------------------------------------------------------------------------------- //
  // @ Accessors
  // ------------------------------------------------------------------------------- //
  get shortUrl$(): Observable<string> {
    return this._shortUrl.asObservable();
  }

  getPropertyAmenities(propertyId): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/api/v1/property-amenities/${propertyId}`)
      .pipe(
        map((results) => {
          return results.data;
        })
      );
  }

  shortenUrl(fullurl: string): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/api/v1/shorten-url`, {
        url: fullurl,
      })
      .pipe(
        tap((shorturl: string) => {
          this._shortUrl.next(shorturl);
        })
      );
  }

  page(name: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/api/v1/dialog-page/${name}`
    );
  }

  sendSignInLink(_email: string): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/api/v1/signin-w-link-request`,
      { email: _email }
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DialogsModule } from '../dialogs/dialogs.module';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout.component';
import { DefaultComponent } from './layouts/default/default.component';
import { EmptyComponent } from './layouts/empty/empty.component';
import { ToasterComponent } from '../shared/components/toast/toaster/toaster.component';
import { ToastComponent } from '../shared/components/toast/toast/toast.component';
import { NgxGoogleAddressAutocompleteModule } from 'ngx-google-address-autocomplete';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LayoutComponent,
    DefaultComponent,
    EmptyComponent,
    ToasterComponent,
    ToastComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DialogsModule,
    NgxGoogleAddressAutocompleteModule,
  ],
  exports: [HeaderComponent, FooterComponent],
})
export class LayoutModule {}

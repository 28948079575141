import { Injectable, Component, ViewEncapsulation } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private _router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        console.log('ERR:', err);
        if (
          err.status === 401 &&
          !request.url.includes('create-booking-attempt', 0) &&
          err?.error?.message !== 'Unauthorized'
        ) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();

          this._router.navigateByUrl(
            'auth/login?redirect_url=' + this._router.url
          );
          //location.reload(true);
        }

        // const error = err.error.message || err.statusText;

        return throwError(err);
      })
    );
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-layout-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class FooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { TolisDialogRef } from '../tolis-dialog/tolis-dialogref';
import { DIALOG_DATA } from '../tolis-dialog/tolis-dialog-tokens';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { mxvAnimations } from 'src/@tolis/animations';
import { DialogsService } from '../dialogs.service';

@Component({
  selector: 'app-magic-link-signin.dialog',
  templateUrl: './magic-link-signin.dialog.component.html',
  styleUrl: './magic-link-signin.dialog.component.css',
  standalone: false,
  encapsulation: ViewEncapsulation.None,
  animations: mxvAnimations,
})
export class MagicLinkSigninDialog implements OnInit, OnDestroy {
  _unsubscribeAll: Subject<void> = new Subject();
  magicLinkForm: UntypedFormGroup;

  constructor(
    private _dialogRef: TolisDialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private _dialogsService: DialogsService
  ) {}

  ngOnInit(): void {
    this.magicLinkForm = this.formBuilder.group({
      email: [this.data?.email ?? '', [Validators.required, Validators.email]],
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
  onSubmit(event) {
    console.log('sdsdsdsdsdsds', this.magicLinkForm.get('email').value);
    this._dialogsService
      .sendSignInLink(this.magicLinkForm.get('email').value)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe();
  }

  dismiss() {
    this._dialogRef.close('x');
  }
}

<div class="d-block modal" @zoomIn @zoomOut>
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
        <div class="modal-content shadow-lg">
            <div class="modal-header bg-danger text-white">
                <h5 class="modal-title">Login With Link</h5>
                <button type="button" class="btn-close" title="Close" (click)="dismiss()"></button>
            </div>
            <div class="modal-body">
                <div class="px-md-3 px-xl-2 pt-4">
                    <form autocomplete="off" class="form-validate form-inline" [formGroup]="magicLinkForm"
                        (ngSubmit)="onSubmit($event)">
                        <div class="form-group">
                            <div class="form-group mb-2">
                                <label for="email">Email Address</label>
                                <input placeholder="Your Email" autocomplete="off" class="form-control"
                                    formControlName="email" />
                            </div>
                            <button type="button" class="btn btn-primary mb-2" (click)="onSubmit($event)">Send Link
                            </button>

                            <hr data-content="Enter your email address" class="mt-2 hr-text letter-spacing-1">
                            <p class="text-muted">If you have an account with us we will send you a link
                                to your email which you can click to automatically Login without a password</p>
                        </div>
                    </form>
                </div>
                <div class="mt-4 modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="dismiss()">Close</button>
                </div>
            </div>
        </div>
    </div>